  
import React from "react"

import { Button, Icon } from "@chakra-ui/core"

const ShareGame = ({
  url = "https://geography.games/",
  ...restProps
}) => {

  const twitterRoot = "http://twitter.com/intent/tweet"
  const shareText = `Challenge yourself and get to know the world with our beautiful geography quizzes! 🌍`
  const encodedShareText = encodeURIComponent(shareText)
  const shareUrl = `${twitterRoot}?url=${url}&text=${encodedShareText}&original_referer=${url}`
  
  return (
    <Button
      as="a"
      href={shareUrl}
      fontSize="2.5rem"
      bg="transparent"
      borderRadius="full"
      w="3rem"
      h="3rem"
      _hover={{
        bg: "mint.200"
      }}
      _focus={{
        bg: "mint.200"
      }}
      _active={{
        bg: "mint.200"
      }}
      {...restProps}
    >
      <Icon name="twitter" w="1.5rem" h="1.5rem" />
    </Button>
  )
}

export default ShareGame
