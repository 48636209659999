
import React from "react"
import { Box, Divider, Heading, Text, Grid, Stack, Image, SimpleGrid, AspectRatioBox } from "@chakra-ui/core"
import { Link as GLink, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "components/SEO"
import Link from "components/Link"
import ShareGame from "components/ShareGame"
import Newsletter from "components/Newsletter"
import geographyGamesLogo from "images/favicon.png"
import geographyGamesCover from "images/geography-games-cover.png"

const QuizPreview = ({ cover, title, published, ...restProps }) => {
  return published ? (
    <Box as="article" gridColumn={["span 6", "span 3", null, null, "span 2"]} {...restProps}>
      <GLink to="/europe-quiz" className="game-preview-link">
        <Box overflow="hidden" borderRadius="lg">
          <AspectRatioBox ratio={1}>
            <Box className="cover">
              <Img fluid={cover.childImageSharp.fluid} />
            </Box>
          </AspectRatioBox>
        </Box>
        <Heading fontSize="2rem" textTransform="uppercase" textAlign="center" pt="0.5rem">
          {title}
        </Heading>
      </GLink>
    </Box>
  ) : (
    <Box as="article" gridColumn={["span 6", "span 3", null, null, "span 2"]} opacity={0.3} {...restProps}>
      <Box overflow="hidden" borderRadius="lg">
        <AspectRatioBox ratio={1}>
          <Box className="cover">
            <Img fluid={cover.childImageSharp.fluid} />
          </Box>
        </AspectRatioBox>
      </Box>
      <Heading fontSize="2rem" textTransform="uppercase" textAlign="center" pt="0.5rem">
        {title}
      </Heading>
      <Text textAlign="center" fontSize="0.875rem" lineHeight="1rem" mt="0.5rem">{ "Coming soon" }</Text>
    </Box>
  )
}

const IndexPage = ({ data }) => {

  const quizzes = [
    { key: 1, cover: data.europeCover, title: "Europe Quiz", published: true },
    { key: 2, cover: data.worldCover, title: "World Quiz", published: false },
    { key: 3, cover: data.oceaniaCover, title: "Oceania Quiz", published: false },
  ]

  return (
    <>
      <SEO
        title="Geography Games and Quizzes"
        description="Beautiful and fun geography quizzes created by z creative labs. Challenge yourself and get to know the world, one game at a time!"
        cover={geographyGamesCover}
        path="/"
      />

      <Grid
        gridTemplateColumns={["6rem 1fr 6rem", null, "12rem 12rem 1fr 12rem 12rem"]}
        gridGap="0"
        h="9rem"
        alignItems="center"
        zIndex={99999}
        position="relative"
        bg="white"
      >
        <Box gridColumn="1 / 2" textAlign="center">
          <GLink to="/">
            <Image src={geographyGamesLogo} w="4rem" h="4rem" display="inline-block" />
          </GLink>
        </Box>
        <Box gridColumn="-2 / -1" textAlign="center">
          <ShareGame />
        </Box>
      </Grid>

      <Box bg="white" overflow="hidden" mx="auto" maxWidth="96rem">
        <Stack spacing={["3rem", "5rem"]} mb="3rem" px={["0", null, "2rem"]}>
          <Stack spacing="1.5rem" textAlign="center" justifyContent="center" alignItems="center" px={["1.25rem", null, null, "2.5rem"]}>
            <Heading textTransform="uppercase" fontSize={["3rem", "4rem"]} lineHeight={["3.5rem", "4.5rem"]}>
              { "Geography games" }
            </Heading>
            <Text maxWidth="45rem" fontSize={["1rem", null, "1.25rem"]} lineHeight={["1.75rem", null, "2rem"]}>
              { "Challenge yourself with our beautiful geography games and quizzes. Subscribe to our newsletter and receive the latest games right in your inbox!" }
            </Text>
            <Newsletter />
          </Stack>
          <SimpleGrid columns={6} gridGap="1.25rem" px={["1.25rem", null, null, "2.5rem"]}>
            {quizzes.map(quiz => <QuizPreview key={quiz.key} {...quiz} />)}
          </SimpleGrid>
        </Stack>

        <Stack spacing="2.5rem" px={["1.25rem", null, null, "2.5rem"]} pb="2.5rem">
          <Divider />
          <Box w="100%" maxWidth="90rem" mx="auto" textAlign="center" mt="1.25rem">
            <Text fontSize="0.75rem" lineHeight="1.25rem" pb="1.25rem">
              { `© ${ new Date().getFullYear() } `}
              <Link to="https://www.zcreativelabs.com/" textDecoration="underline">
                {"z creative labs"}
              </Link>
              {" GmbH" }
            </Text>
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export const query = graphql`
  query IndexQuery{
    europeCover: file(relativePath: { eq: "europe-quiz-cover-square.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    worldCover: file(relativePath: { eq: "world-quiz-cover-square.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oceaniaCover: file(relativePath: { eq: "oceania-quiz-cover-square.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
